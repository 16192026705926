<template>
  <div :class="buttonClass">
    <p-button
      color-type="tertiary"
      icon="link"
      title="Insert/edit link (⌘K)"
      @click="openModal"
      :disabled.prop="buttonDisabled"
    />

    <link-modal
      :show="showLinkModal"
      :editMode="isEditingLink"
      :text="linkText"
      :url="linkUrl"
      :title="linkTitle"
      :target="linkTarget"
      :hasMultipleParagraphs="linkHasMultipleParagraphs"
      :popovers="popovers"
      :sections="sections"
      :editor="editor"
      @insert-link="insertLink"
      @close-request="closeModal"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import { EditorPageOption } from '../../types';
import LinkModal from '@/components/ui/form/texteditor/modals/LinkModal.vue';
import { Iframe } from '@/iframe';

@Component({
  components: { LinkModal }
})
export default class EditorLink extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;
  @Prop({ type: Array, required: false, default: () => [] }) public readonly popovers!: EditorPageOption[];
  @Prop({ type: Array, required: false, default: () => [] }) public readonly sections!: EditorPageOption[];

  public showLinkModal = false;
  public linkType = 'url';
  public linkUrl = '';
  public linkText = '';
  public linkTitle = '';
  public linkTarget = '_self';
  public linkHasMultipleParagraphs = false;
  public isEditingLink = false;
  public selectedPopover: number | null = null;
  public selectedSection: number | null = null;

  public get buttonDisabled() {
    return !this.editor.can().chain().focus().toggleLink({ href: this.linkUrl }).run();
  }

  public get buttonClass() {
    return { 'toolbar-button--active': this.editor.isActive('link') };
  }

  // Opens the link modal and determines whether we are editing or inserting a new link
  public openModal() {
    const { from, to } = this.editor.state.selection;
    const linkTagData = this.isInsideLinkTag();

    if (!linkTagData && from === to) {
      return;
    }

    this.linkHasMultipleParagraphs = this.hasMultipleParagraphs(from, to);

    if (linkTagData) {
      this.linkText = linkTagData.text;
      this.linkUrl = linkTagData.href;
      this.linkTitle = linkTagData.title;
      this.linkTarget = linkTagData.target;
      this.isEditingLink = true;
    } else if (from !== to && !linkTagData) {
      const linkText = this.editor.state.doc.textBetween(from, to, ' ');
      this.linkText = linkText.trim();
      this.isEditingLink = false;
    } else {
      this.resetLinkData();
    }

    Iframe.emit('frame-request-full-size');
    this.determineLinkType();
    this.showLinkModal = true;
  }

  public hasMultipleParagraphs(from: number, to: number): boolean {
    const doc = this.editor?.state.doc;
    if (!doc) return false;

    let paragraphCount = 0;
    doc.nodesBetween(from, to, (node) => {
      if (node.type.name === 'paragraph') {
        paragraphCount++;
      }
    });

    return paragraphCount > 1;
  }

  // Determines the type of link (URL, popover, section)
  public determineLinkType() {
    if (this.linkUrl.startsWith('#/?page=')) {
      this.linkType = 'popover';
      this.selectedPopover = Number(this.linkUrl.replace('#/?page=', ''));
    } else if (this.linkUrl.startsWith('#/?section=')) {
      this.linkType = 'section';
      this.selectedSection = Number(this.linkUrl.replace('#/?section=', ''));
    } else if (this.linkUrl === '#/?close-popover=1') {
      this.linkType = 'close-popover';
    } else {
      this.linkType = 'url';
    }
  }

  // Checks if the selection is inside an <a> tag
  public isInsideLinkTag() {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return null;

    const range = selection.getRangeAt(0);
    const { startContainer } = range;
    let node = startContainer;

    while (node) {
      if (node.nodeName.toLowerCase() === 'a' && node instanceof HTMLAnchorElement) {
        return {
          href: node.getAttribute('href') || '',
          title: node.getAttribute('title') || '',
          target: node.getAttribute('target') || '_self',
          text: node.innerText.trim() || ''
        };
      }
      // @ts-ignore
      node = node.parentNode;
    }
    return null;
  }

  // Inserts or updates a link in the editor
  public insertLink(linkData: {
    linkType: string;
    linkUrl: string;
    linkText: string;
    linkTitle: string;
    linkTarget: string;
    selectedPopover: string;
    selectedSection: string;
  }) {
    let href = linkData.linkUrl;

    if (linkData.linkType === 'popover' && linkData.selectedPopover) {
      href = `#/?page=${linkData.selectedPopover}`;
    } else if (linkData.linkType === 'section' && linkData.selectedSection) {
      href = `#/?section=${linkData.selectedSection}`;
    } else if (linkData.linkType === 'close-popover') {
      href = `#/?close-popover=1`;
    }

    if (href) {
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({
          href,
          target: linkData.linkTarget,
          // @ts-ignore
          title: linkData.linkTitle
        })
        .run();

      if (this.isEditingLink && linkData.linkText) {
        this.editor
          .chain()
          .focus()
          .insertContent(linkData.linkText, {
            // @ts-ignore
            from: this.editor.state.selection.from,
            to: this.editor.state.selection.to
          })
          .run();
      } else if (linkData.linkText) {
        this.editor.chain().focus().insertContent(linkData.linkText).run();
      }
    } else {
      this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }

    this.closeModal();
  }

  // Resets the link-related fields
  public resetLinkData() {
    this.linkType = 'url';
    this.linkUrl = '';
    this.linkText = '';
    this.linkTitle = '';
    this.linkTarget = '_self';
    this.selectedPopover = null;
    this.selectedSection = null;
    this.isEditingLink = false;
    this.linkHasMultipleParagraphs = false;
  }

  public mounted() {
    // Listen for the keyboard shortcut
    window.addEventListener('keydown', this.handleShortcut);
  }

  public beforeDestroy() {
    // Cleanup the event listener
    window.removeEventListener('keydown', this.handleShortcut);
  }

  public handleShortcut(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      this.openModal(); // Trigger modal opening when Command/Ctrl + K is pressed
    }
  }

  // Cancels the link editing/insertion and closes the modal
  public closeModal() {
    this.resetLinkData();
    this.showLinkModal = false;
  }
}
</script>
