import { Heading } from '@tiptap/extension-heading';

export const CustomHeading = Heading.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      dynamic: {
        default: {},
        parseHTML: (element) => {
          const attributes: Record<string, string> = {};
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null) {
              attributes[attr] = value;
            }
          });
          return attributes;
        },
        renderHTML: (attributes) => attributes.dynamic || {}
      }
    };
  },

  parseHTML() {
    return this.options.levels.map((level) => ({
      tag: `h${level}`,
      getAttrs: (node: HTMLElement) => {
        const attributes: any = {};
        // Capture standard attributes and dynamically capture others
        node.getAttributeNames().forEach((attr) => {
          attributes[attr] = node.getAttribute(attr);
        });
        return { ...attributes, level };
      }
    }));
  },

  renderHTML({ node, HTMLAttributes }) {
    const { level } = node.attrs;

    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.dynamic
    };

    // Remove 'dynamic' to prevent duplication
    delete attrs.dynamic;

    return [`h${level}`, attrs, 0];
  }
});
