import { Paragraph } from '@tiptap/extension-paragraph';

export const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      dynamic: {
        default: {},
        parseHTML: (element) => {
          const attributes: Record<string, string> = {};
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null) {
              attributes[attr] = value;
            }
          });
          return attributes;
        },
        renderHTML: (attributes) => attributes.dynamic || {}
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'p',
        getAttrs: (node: HTMLElement) => {
          const attributes: any = {};

          // Capture standard and dynamic attributes
          node.getAttributeNames().forEach((attr) => {
            attributes[attr] = node.getAttribute(attr);
          });

          return { dynamic: attributes };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // Merge dynamic attributes with other HTML attributes
    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.dynamic
    };

    delete attrs.dynamic;

    return ['p', attrs, 0];
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const isParagraphActive = editor.isActive('paragraph');
        const isBulletListActive = editor.isActive('bulletList');
        const isOrderedListActive = editor.isActive('orderedList');

        // Apply custom behavior only if inside a paragraph and not inside any list
        if (isParagraphActive && !isBulletListActive && !isOrderedListActive) {
          editor.commands.splitBlock();
          editor.commands.setNode('paragraph', { class: null, style: null, id: null });
          return true;
        }

        return false;
      }
    };
  }
});
