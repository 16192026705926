import { TableCell as DefaultTableCell } from '@tiptap/extension-table-cell';

export const CustomTableCell = DefaultTableCell.extend({
  addOptions() {
    return {
      ...this.parent?.()
    };
  },

  addAttributes() {
    return {
      ...this.parent?.()
    };
  },

  // Customize the content schema to accept only inline content
  addProseMirrorPlugins() {
    return [];
  },

  content: 'inline*', // Allow only inline content, no block elements

  parseHTML() {
    return [
      {
        tag: 'td',
        getAttrs: (node: HTMLElement) => ({
          colspan: node.getAttribute('colspan'),
          rowspan: node.getAttribute('rowspan')
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['td', HTMLAttributes, 0]; // Render the <td> directly without <p> inside
  }
});
