import { Extension } from '@tiptap/core';

export interface TextAlignOptions {
  types: string[];
  alignments: string[];
  defaultAlignment: string | undefined;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textAlign: {
      setTextAlign: (alignment: string) => ReturnType;
      unsetTextAlign: () => ReturnType;
    };
  }
}

// overwriting the default `text-align` extension
// so that we can have text alignment left style
// on new line we reset the text align and only adds it if the user specific set the text align for the new line
export default Extension.create<TextAlignOptions>({
  name: 'textAlign',

  addOptions() {
    return {
      types: [],
      alignments: ['left', 'center', 'right', 'justify'],
      defaultAlignment: undefined
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textAlign: {
            default: this.options.defaultAlignment,
            parseHTML: (element) => {
              const alignment = element.style.textAlign || this.options.defaultAlignment;
              return alignment && this.options.alignments.includes(alignment)
                ? alignment
                : this.options.defaultAlignment;
            },
            renderHTML: (attributes) => {
              if (!attributes.textAlign) {
                return {};
              }

              // Force `text-align` to be added even if it's `left`
              return { style: `text-align: ${attributes.textAlign}` };
            }
          }
        }
      }
    ];
  },

  addCommands() {
    return {
      setTextAlign:
        (alignment: string) =>
        ({ commands }) => {
          if (!this.options.alignments.includes(alignment)) {
            return false;
          }

          return this.options.types
            .map((type) => commands.updateAttributes(type, { textAlign: alignment }))
            .every((response) => response);
        },

      unsetTextAlign:
        () =>
        ({ commands }) => {
          return this.options.types
            .map((type) => commands.resetAttributes(type, 'textAlign'))
            .every((response) => response);
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const isInListItem = this.editor.state.selection.$from.node(-1).type.name === 'listItem';

        // we don't want to split the list item on enter
        if (!isInListItem) {
          const isNewLine = this.editor.commands.splitBlock();
          if (isNewLine) {
            this.editor.commands.unsetTextAlign();
          }
          return true;
        } else {
          return false;
        }
      },
      'Mod-Shift-l': () => this.editor.commands.setTextAlign('left'),
      'Mod-Shift-e': () => this.editor.commands.setTextAlign('center'),
      'Mod-Shift-r': () => this.editor.commands.setTextAlign('right'),
      'Mod-Shift-j': () => this.editor.commands.setTextAlign('justify')
    };
  }
});
