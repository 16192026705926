import { Node } from '@tiptap/core';

export const CustomSpan = Node.create({
  name: 'span',

  group: 'inline', // This defines that the span element is an inline element

  content: 'text*', // Spans generally contain text

  inline: true, // Set to true, as span is an inline element

  atom: false, // Set atom to false since spans are not atomic

  addAttributes() {
    return {
      // Handle any attribute dynamically
      dynamic: {
        default: {},
        parseHTML: (element) => {
          const attributes: Record<string, string> = {};
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null) {
              attributes[attr] = value;
            }
          });
          return attributes;
        },
        renderHTML: (attributes) => attributes.dynamic || {}
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (node: HTMLElement) => {
          const attributes: any = {};

          // Loop through all attributes of the node and preserve them
          node.getAttributeNames().forEach((attr) => {
            attributes[attr] = node.getAttribute(attr); // Dynamically add all attributes
          });

          return { dynamic: attributes };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.dynamic
    };

    delete attrs.dynamic;

    return ['span', attrs, 0];
  }
});
