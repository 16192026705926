<template>
  <p-form-element
    :disabled="disabled"
    :label="label"
    :help-text="helpText"
    :help-in-tooltip="helpInTooltip"
    :error="error"
    :size="size"
    :ai-assistant="aiAssistant"
    :modelValue="modelValue ?? ''"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <p-text-editor
      :disabled="disabled"
      :error="!!error"
      :toolbar="toolbar"
      :sections="sections"
      :popovers="popovers"
      :placeholder="placeholder"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { EditorPageOption } from './types';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly aiAssistant!: boolean;

  @Prop({ type: String as PropType<'medium' | 'large' | 'extra-large'>, required: false, default: 'extra-large' })
  public readonly size!: 'medium' | 'large' | 'extra-large';

  @Prop({
    type: String as PropType<'DIV' | 'P'>,
    required: false,
    default: undefined,
    validator: (value) => value === 'DIV' || value === 'P'
  })
  public readonly forcedRootBlock?: 'DIV' | 'P';

  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Array, required: false, default: () => [] }) public readonly toolbar!: string[];
  @Prop({ type: Array, required: false, default: () => [] }) public readonly sections!: EditorPageOption[];
  @Prop({ type: Array, required: false, default: () => [] }) public readonly popovers!: EditorPageOption[];
}
</script>
