import { Image } from '@tiptap/extension-image';

export default Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      // Handle width attribute
      width: {
        default: null,
        parseHTML: (element) => element.getAttribute('width'),
        renderHTML: (attributes) => {
          if (!attributes.width) {
            return {};
          }

          return {
            width: attributes.width
          };
        }
      },
      // Handle height attribute
      height: {
        default: null,
        parseHTML: (element) => element.getAttribute('height'),
        renderHTML: (attributes) => {
          if (!attributes.height) {
            return {};
          }

          return {
            height: attributes.height
          };
        }
      },
      // Dynamically capture other attributes
      dynamic: {
        default: {},
        parseHTML: (element) => {
          const attributes: Record<string, string> = {};
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null && !['width', 'height', 'src', 'alt'].includes(attr)) {
              attributes[attr] = value;
            }
          });

          return attributes;
        },
        renderHTML: (attributes) => {
          return attributes.dynamic || {};
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
        getAttrs: (node: HTMLElement) => {
          const attributes: any = {};

          node.getAttributeNames().forEach((attr) => {
            attributes[attr] = node.getAttribute(attr);
          });

          return { dynamic: attributes };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.dynamic
    };

    delete attrs.dynamic;

    return ['img', attrs];
  }
});
