<template>
  <div>
    <p-button color-type="tertiary" icon="image" title="Media selector" @click="handleAddImage" :class="buttonClass" />
    <!-- Media Modal -->
    <p-modal-media
      v-if="showMediaModal"
      :extensions="['png', 'gif', 'jpeg', 'jpg', 'svg']"
      selection
      @select="onMediaSelect"
      @close-request="closeMediaDialog"
    />

    <image-detail-modal
      v-if="showImageDetailsModal"
      :show="showImageDetailsModal"
      :imageSourceProp="selectedImage"
      :altTextProp="selectedAltText"
      :imageWidthProp="selectedImageWidth"
      :imageHeightProp="selectedImageHeight"
      @insert-image="insertImageDetails"
      @close-request="closeImageDetailsModal"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import { Iframe } from '@/iframe';
import ImageDetailModal from '@/components/ui/form/texteditor/modals/ImageDetailModal.vue';

@Component({
  components: { ImageDetailModal }
})
export default class EditorMediaSelector extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  public showMediaModal = false;
  public showImageDetailsModal = false;
  public selectedImage = '';
  public selectedAltText = '';
  public selectedImageWidth = 0;
  public selectedImageHeight = 0;

  public get buttonClass() {
    return { 'toolbar-button--active': this.editor.isActive('image') };
  }

  // Opens the media dialog for selecting an image
  public handleAddImage() {
    this.openMediaDialog();
  }

  public openMediaDialog() {
    Iframe.emit('frame-request-full-size');
    this.showMediaModal = true;
    this.$emit('media-dialog-open');
  }

  public closeMediaDialog() {
    this.showMediaModal = false;

    Iframe.emit('frame-request-regular-size-before');
    setTimeout(() => {
      Iframe.emit('frame-request-regular-size');
    }, 475);

    this.$emit('media-dialog-close');
  }

  // Closes the Image Details modal
  public closeImageDetailsModal() {
    this.showImageDetailsModal = false;

    Iframe.emit('frame-request-regular-size-before');
    setTimeout(() => {
      Iframe.emit('frame-request-regular-size');
    }, 475);

    this.$emit('media-dialog-close');
  }

  // Get selected image attributes from Tiptap
  public openImageDetailsModal() {
    if (this.editor?.isActive('image')) {
      // If an image is already selected, fetch its attributes
      const attrs = this.editor.getAttributes('image');
      this.selectedImage = attrs.src || '';
      this.selectedAltText = attrs.alt || '';
      this.selectedImageWidth = attrs.width || 0;
      this.selectedImageHeight = attrs.height || 0;
      Iframe.emit('frame-request-full-size');
      this.showImageDetailsModal = true;
      this.$emit('media-dialog-open');
    }
  }

  public insertImageDetails(imageData: {
    imageSource: string;
    altText: string;
    imageWidth: number;
    imageHeight: number;
  }) {
    this.editor
      .chain()
      .focus()
      .setImage({
        src: imageData.imageSource,
        alt: imageData.altText,
        // @ts-ignore
        width: imageData.imageWidth,
        // @ts-ignore
        height: imageData.imageHeight
      })
      .run();

    this.showImageDetailsModal = false; // Close the modal after inserting/updating
  }

  // Handles the image selection and inserts it into the editor
  public onMediaSelect(path: string) {
    if (path) {
      this.editor.chain().focus().setImage({ src: path }).run();
      this.closeMediaDialog();
    }
  }
}
</script>
