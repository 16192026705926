<template>
  <div class="toolbar">
    <editor-bold v-if="computedToolbar.includes('bold')" :editor="editor" />
    <editor-italic v-if="computedToolbar.includes('italic')" :editor="editor" />
    <editor-underline v-if="computedToolbar.includes('underline')" :editor="editor" />
    <editor-block v-if="computedToolbar.includes('blocks')" :editor="editor" />
    <editor-color-picker v-if="computedToolbar.includes('forecolor')" :editor="editor" />
    <editor-text-align v-if="computedToolbar.includes('align')" :editor="editor" />
    <editor-link
      v-if="computedToolbar.includes('link')"
      :editor="editor"
      :popovers="popovers"
      :sections="sections"
      ref="linkComponent"
    />
    <editor-media-selector ref="mediaComponent" v-if="computedToolbar.includes('mediaSelector')" :editor="editor" />
    <editor-undo v-if="computedToolbar.includes('undo')" :editor="editor" />
    <editor-redo v-if="computedToolbar.includes('redo')" :editor="editor" />
    <editor-numbered-list v-if="computedToolbar.includes('numlist')" :editor="editor" />
    <editor-bullet-list v-if="computedToolbar.includes('bullist')" :editor="editor" />
    <editor-source-code
      v-if="computedToolbar.includes('code')"
      :source-code="modelValue"
      :editor="editor"
      @update="$emit('updateSourceCode')"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import EditorBold from '@/components/ui/form/texteditor/toolbar/EditorBold.vue';
import EditorItalic from '@/components/ui/form/texteditor/toolbar/EditorItalic.vue';
import EditorUnderline from '@/components/ui/form/texteditor/toolbar/EditorUnderline.vue';
import EditorBlock from '@/components/ui/form/texteditor/toolbar/EditorBlock.vue';
import EditorTextAlign from '@/components/ui/form/texteditor/toolbar/EditorTextAlign.vue';
import EditorLink from '@/components/ui/form/texteditor/toolbar/EditorLink.vue';
import EditorUndo from '@/components/ui/form/texteditor/toolbar/EditorUndo.vue';
import EditorRedo from '@/components/ui/form/texteditor/toolbar/EditorRedo.vue';
import EditorNumberedList from '@/components/ui/form/texteditor/toolbar/EditorNumberedList.vue';
import EditorBulletList from '@/components/ui/form/texteditor/toolbar/EditorBulletList.vue';
import EditorSourceCode from '@/components/ui/form/texteditor/toolbar/EditorSourceCode.vue';
import EditorMediaSelector from '@/components/ui/form/texteditor/toolbar/EditorMediaSelector.vue';
import EditorColorPicker from '@/components/ui/form/texteditor/toolbar/EditorColorPicker.vue';
import { EditorPageOption } from '@/components/ui/form/types';

@Component({
  components: {
    EditorSourceCode,
    EditorBulletList,
    EditorNumberedList,
    EditorRedo,
    EditorUndo,
    EditorMediaSelector,
    EditorLink,
    EditorTextAlign,
    EditorColorPicker,
    EditorBlock,
    EditorUnderline,
    EditorItalic,
    EditorBold
  }
})
export default class EditorToolbar extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;
  @Prop({ type: Array, required: true }) public readonly computedToolbar!: string[];
  @Prop({ type: Array, required: false }) public readonly sections?: EditorPageOption[];
  @Prop({ type: Array, required: false }) public readonly popovers?: EditorPageOption[];
  @Prop({ type: String, required: true }) public readonly modelValue!: string;

  public openImageDetailsModal() {
    if (this.$refs.mediaComponent instanceof EditorMediaSelector) {
      this.$refs.mediaComponent.openImageDetailsModal();
    }
  }

  public triggerOpenLinkModal() {
    if (this.$refs.linkComponent instanceof EditorLink) {
      this.$refs.linkComponent.openModal();
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  gap: 3px;
  padding: 3px;
  border-top-left-radius: var(--field-border-radius-default);
  border-top-right-radius: var(--field-border-radius-default);
  border: 1px solid var(--field-color-border-default);
  align-items: center;
  flex-wrap: wrap;

  .toolbar-button--active {
    background: #a6ccf7;
    border-radius: 2px;
  }
}
</style>
