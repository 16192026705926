<template>
  <div>
    <p-button title="Source code" color-type="tertiary" icon="code" @click="openModal" />
    <CodeModal
      :show="isModalVisible"
      :html="beautifiedHtmlContent"
      @update:html="setHtmlContent"
      @close="closeModal"
      @insert="insertHtmlIntoEditor"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import CodeModal from '@/components/ui/form/texteditor/modals/CodeModal.vue';
import { Iframe } from '@/iframe';
import { html as beautifyHtml } from 'js-beautify';

@Component({
  components: { CodeModal }
})
export default class EditorSourceCode extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;
  @Prop({ type: String, default: '' }) public readonly sourceCode!: string;

  public isModalVisible = false;
  public htmlContent = '';

  // Computed property to get beautified HTML content
  public get beautifiedHtmlContent() {
    return beautifyHtml(this.htmlContent, { indent_size: 2 });
  }

  @Watch('sourceCode', { immediate: true })
  public updateHtmlContent() {
    // Beautify source code when the component is initialized or when the sourceCode prop changes
    this.htmlContent = beautifyHtml(this.sourceCode, { indent_size: 2 });
  }

  public openModal() {
    Iframe.emit('frame-request-full-size');
    this.isModalVisible = true;
  }

  public closeModal() {
    Iframe.emit('frame-request-regular-size-before');
    setTimeout(() => {
      Iframe.emit('frame-request-regular-size');
    }, 475);

    this.isModalVisible = false;
  }

  public setHtmlContent(newContent: string) {
    this.htmlContent = newContent; // Allow editing in the modal without beautification
  }

  public insertHtmlIntoEditor() {
    if (this.htmlContent) {
      // Beautify HTML content before inserting into the editor
      const beautifiedContent = beautifyHtml(this.htmlContent, { indent_size: 2 });
      this.editor.commands.setContent(beautifiedContent);
    }

    this.$emit('update');
    this.closeModal();
  }
}
</script>
